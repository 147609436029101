import React, { useState } from "react";
import GoogleCalendar from "../../public/google-calendar.svg";
import OutlookCalendar from "../../public/outlook.svg";
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@mindstonehq/ui";
import { generateICSFile, ICSEvent } from "~/utils/ics-file-utils";
import { CalendarExportDialog } from "~/components/dialogs/calendar-export-dialog";
import metrics from "~/utils/metrics";

export default function AddToCalendar({
  events,
  track,
  children,
  variant = "outline",
  size = "default",
  className,
  downloadFilename,
}: React.PropsWithChildren<{
  track?: () => void;
  events: ICSEvent[];
  downloadFilename?: string;
  variant?: "outline" | "secondary" | "default" | "ghost";
  size?: "sm" | "default" | "lg" | "icon";
  className?: string;
}>) {
  const [showCalendarExportInstructions, setShowCalendarExportInstructions] =
    useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const formatDateTime = (date: string) =>
    new Date(date).toISOString().replace(/-|:|\.\d\d\d/g, "");

  function createGoogleCalendarUrl(e: ICSEvent) {
    const startTime = formatDateTime(e.startDatetime);
    const endTime = formatDateTime(e.endDatetime);
    const details = encodeURIComponent(e.description || "");
    const location = encodeURIComponent(e.joinUrl || e.publicUrl || "");
    const title = encodeURIComponent(e.name);

    return `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${title}&dates=${startTime}/${endTime}&details=${details}&location=${location}`;
  }

  function handleAddToGoogleCalendar() {
    if (track) track();
    metrics.track("live_session_calendar", {
      option: "google",
      eventCount: events.length,
    });
    setDropdownOpen(false);
    if (events.length === 1) {
      window.open(createGoogleCalendarUrl(events[0]), "_blank");
    } else {
      generateICSFile(events, downloadFilename || "Mindstone calendar invite");
      setShowCalendarExportInstructions(true);
    }
  }

  function handleAddToOtherCalendars() {
    if (track) track();
    metrics.track("live_session_calendar", {
      option: "other",
      eventCount: events.length,
    });
    setDropdownOpen(false);
    generateICSFile(events, downloadFilename || "Mindstone calendar invite");
    setShowCalendarExportInstructions(true);
  }

  return (
    <>
      <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
        <DropdownMenuTrigger asChild>
          <Button variant={variant} size={size} className={className}>
            {children}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem onClick={handleAddToGoogleCalendar}>
            <img src={GoogleCalendar} className="mr-2 h-4 w-4" />
            Add to Google Calendar
          </DropdownMenuItem>
          <DropdownMenuItem onClick={handleAddToOtherCalendars}>
            <img src={OutlookCalendar} className="mr-2 h-4 w-4" />
            Add to other calendars
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <CalendarExportDialog
        isOpen={showCalendarExportInstructions}
        onClose={() => setShowCalendarExportInstructions(false)}
        events={events}
        programName={downloadFilename || "Mindstone"}
      />
    </>
  );
}
